<template>
	<div class="menu_nav">
		<div class="banner">
			<img class="img" :src="$utils.imgSrc(banner)" alt="">
		</div>
		<div class="nav-wrap">
			<div class="banner-container">
				<div class="about-box">
					<!-- <img class="about-icon" src="@/assets/about/nei-ico1.jpg" alt="" srcset=""> -->
					<span class="about-text">{{title}}</span>
					<i class="el-icon-caret-right"></i>
				</div>
				<div class="nav-box">
					<span class="nav-item" v-for="(nav, index) in navs" :key="index" @click="navCheck(nav)">{{nav.name}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			banner: {
				type: String,
				default: ''
			},
			navs: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				copyMenus: []
			}
		},
		computed: {
			menus() {
				return this.$store.state.common.menus
			}
		},
		created() {
			this.copyMenus = this.getArray(this.menus)
		},
		methods: {
			navCheck(item) {
				let router = this.copyMenus.filter(menu => {
					return menu.name == item.name
				})
				this.$store.dispatch('common/setMenu', router[0])
				let routerPath = router[0].apiKey ? router[0].link + '?key=' + router[0].apiKey : router[0].link
				this.$router.push(routerPath)
			},
			getArray(arr) {
				let list = []
				arr.map(item => {
					if (item.list && item.list.length) {
						list = list.concat(this.getArray(item.list))
					} else {
						list.push(item)
					}
				})
				return list
			}
		},
	}
</script>

<style lang="scss" scoped>
	.menu_nav {
		.banner {
			overflow: hidden;
			.img {
				width: 100%;
				max-width: 100%;
				object-fit: cover;
			}
		}
	}
	.nav-wrap {
		margin-bottom: 10px;
		padding-top: 10px;
		background: #fff;
		line-height: 60px;
		box-shadow: 0 10px 10px var(--background-color);
		.about-box {
			display: inline-block;
			.about-icon {
				vertical-align: text-bottom;
			}
			.about-text {
				padding: 0 10px;
				font-size: 20px;
				font-weight: bold;
				position: relative;
				&::before {
					content: '';
					width: 4px;
					height: 20px;
					background: var(--theme-color);
					border-radius: 4px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}
		}
		.nav-box {
			display: inline-block;
			.nav-item {
				padding: 10px 20px;
				cursor: pointer;
				&:hover {
					border-bottom: 2px solid var(--theme-color);
				}
			}
		}
	}
</style>
const state = {
	token: '',
	copyright: '',
	menus: [
		{name: '首页', color: '#007cef', link: '/'},
		{
			name: '关于林安', color: '#35b0bd',
			list: [
				{name: '公司简介', link: '/introduce'},
				{name: '企业文化', link: '/culture'},
				{name: '荣誉资质', link: '/honor'},
				{name: '园区党建', link: '/building'},
			],
		},
		{
			name: '走进园区', color: '#35971a',
			list: [
				{name: '园区概况', link: '/park', apiKey: 'gaikuang'},
				{name: '汽车汽配', link: '/park', apiKey: 'qipei'},
				{name: '物流枢纽', link: '/park', apiKey: 'shuniu'},
				{name: '会展中心', link: '/park', apiKey: 'huizhan'},
				{name: '林安云仓', link: '/park', apiKey: 'yuncang'},
				{name: '跨境贸易', link: '/park', apiKey: 'maoyi'},
				{name: '工程机械', link: '/park', apiKey: 'jixie'},
			],
		},
		{
			name: '入驻企业', color: '#1a4697',
			list: [
				{name: '企业名录', link: '/business'},
			],
		},
		{
			name: '新闻中心', color: '#ffa60c',
			list: [
				{name: '园区动态', link: '/dynamic'},
				{name: '政策法规', link: '/policy'},
			],
		},
		{
			name: '人才发展', color: '#189cdb',
			list: [
				{name: '林安招聘', link: '/recruit'},
				{name: '园区企业招聘', link: '/recruit'},
			],
		},
		{name: '通知公告', color: '#ffc345', link: '/notice'},
		{
			name: '联系我们', color: '#e93f3f',
			list: [
				{name: '联系我们', link: '/contact'},
				{name: '招商合作', link: '/cooperation'},
				{name: '林安物业', link: '/detail', apiKey: 'wuye'},
			],
		},
	],
	menu: {},
	keywords: ''
}

const mutations = {
	SET_TOKEN(state, token) {
		state.token = token
	},
	SET_COPYRIGHT(state, copyright) {
		state.copyright = copyright
		sessionStorage.setItem('copyright', copyright)
	},
	SET_MENU(state, menu) {
		state.menu = Object.assign({}, menu)
		sessionStorage.setItem('menu', JSON.stringify(menu))
	},
	SET_SEARCH(state, keywords) {
		state.keywords = keywords
	}
}

const actions = {
	setCopyright({commit}, copyright) {
		commit('SET_COPYRIGHT', copyright)
	},
	setMenu({commit}, menu) {
		commit('SET_MENU', menu)
	},
	setSearch({commit}, keywords) {
		commit('SET_SEARCH', keywords)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
import CONFIG from '@/config/config.js'

/* 图片路径格式化 */
function imgSrc(src) {
  if (!src) return ''
  if (src.indexOf('http://') !== -1 || src.indexOf('https://') !== -1) return src
  if (src.indexOf('data:image') !== -1) return src
  return CONFIG.imgUrl + src
}

/* 默认图片 */
function defaultImg(img) {
	img.srcElement.src = require('@/assets/placeholder.jpg')
}

export default {
	imgSrc,
	defaultImg
}
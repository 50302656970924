<template>
	<div class="copyright">
		<div class="banner-container">
			<p class="site-logo">
				<a href="/"><img class="logo" src="@/assets/logo.png" alt="" srcset=""></a>
			</p>
			<div class="ql-editor" v-html="copyright"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {
			copyright() {
				return this.$store.state.common.copyright || sessionStorage.getItem('copyright')
			}
		},
	}
</script>

<style lang="scss" scoped>
	.copyright {
		padding: 5px 0;
		font-size: 14px;
		line-height: 30px;
		color: #666;
		text-align: center;
		.site-logo {
			margin-bottom: 10px;
			.logo {
				height: 30px;
			}
		}
	}
</style>
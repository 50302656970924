<template>
  <div id="app">
    <head-nav />
    <router-view/>
		<foot-nav />
		<copy-right />
  </div>
</template>

<script>
	import headNav from '@/components/headNav.vue'
	import footNav from '@/components/footNav.vue'
	import copyRight from '@/components/copyright.vue'
	export default {
		components: {
			headNav,
			footNav,
			copyRight
		},
		data() {
			return {}
		}
	}
</script>

<style lang="scss">
	@import '@/common/common.scss';
	@import '@/assets/quill/quill.bubble.css';
	@import '@/assets/quill/quill.core.css';
	@import '@/assets/quill/quill.snow.css';
	#app {
		font-family: 'Microsoft Yahei',"PingFang SC","Helvetica Neue",Helvetica,Arial,sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #333;
		// text-align: center;
		// color: #2c3e50;
	}
</style>

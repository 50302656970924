import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import CountTo from 'vue-count-to'
Vue.component('CountTo', CountTo)

import menuNav from '@/components/menuNav.vue'
Vue.component('menuNav', menuNav)

// import '@/assets/quill/quill.bubble.css'
// import '@/assets/quill/quill.core.css'
// import '@/assets/quill/quill.snow.css'

Vue.config.productionTip = false

import utils from '@/utils/util'
Vue.prototype.$utils = utils

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
	<div class="header_nav">
		<div class="head_inner banner-container">
			<a class="logo_box" href="/">
				<img class="logo_img" src="@/assets/logo.png" alt="中国(十堰)林安国际商贸物流城" srcset="">
			</a>
			<div class="menu_box">
				<a class="menu_item" v-for="(menu, mindex) in menus" :key="mindex" @mouseenter="menuEnter(menu, mindex)" @mouseleave="menuLeave(menu, mindex)" @click.stop="menuCheck(menu)">
					<div :class="['menu_name', {'active': midx === mindex}]" :style="{background: menu.color}">{{menu.name}}</div>
					<div v-if="menu.list" class="nav_box">
						<a class="nav_item" v-for="(item, index) in menu.list" :key="index" href="javascript:void(0);" @click.stop="menuItem(item)">{{item.name}}</a>
					</div>
				</a>
				<div class="search_box menu_item">
					<div :class="['search_inner', {'input-focus': focused}]">
						<el-input
							placeholder="请输入关键字"
							v-model.trim="keywords"
							maxlength='20'
							@keyup.enter.native="searchKeyword"
							@focus="searchInput(true)"
							@blur="searchInput(false)">
							<i
								class="el-icon-search"
								slot="suffix"
								@click="searchKeyword">
							</i>
						</el-input>
					</div>
				</div>
			</div>
		</div>
		<div :class="['bg-box', (midx !== '' && menus[midx].list ? 'show' : 'hide')]"></div>
	</div>
</template>

<script>
	export default {
		name: 'headNav',
		data() {
			return {
				midx: '',
				keywords: '',
				focused: false
			}
		},
		computed: {
			menus() {
				return this.$store.state.common.menus
			}
		},
		methods: {
			menuCheck(menu) {
				if (menu.link) {
					this.$router.push(menu.link)
				}
			},
			menuItem(item) {
				this.$store.dispatch('common/setMenu', item)
				let routerPath = item.apiKey ? item.link + '?key=' + item.apiKey : item.link
				this.$router.push(routerPath)
			},
			menuEnter(item, index) {
				this.midx = index
			},
			menuLeave() {
				this.midx = ''
			},
			searchKeyword() {
				let word = this.keywords.trim()
				this.$store.dispatch('common/setSearch', word)
				this.$router.push('search?title=' + word)
			},
			searchInput(e) {
				this.focused = e
			}
		},
	}
</script>

<style lang="scss" scoped>
	.header_nav {
		min-width: 1200px;
		background: var(--background-color);
		.head_inner {
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			.logo_box {
				padding: 20px 0;
				.logo_img {
					height: 40px;
				}
			}
			.menu_box {
				display: flex;
				position: relative;
				.menu_item {
					min-width: 5em;
					line-height: 80px;
					text-align: center;
					cursor: pointer;
					&:hover {
						.nav_box {
							display: block;
						}
					}
					&:nth-last-of-type(1) {
						&:hover {
							.nav_box {
								right: 0;
							}
						}
					}
					.menu_name {
						padding: 0 10px;
						height: 8px;
						transition: all .3s;
						&.active {
							color: #fff;
							height: 80px;
						}
					}
					.nav_box {
						display: none;
						padding: 20px 5px;
						color: #333;
						line-height: 40px;
						border-radius: 10px;
						white-space: nowrap;
						position: absolute;
						z-index: 5;
						.nav_item {
							padding: 10px 5px;
							&:hover {
								color: var(--theme-color);
							}
						}
					}
				}
				.search_box {
					width: 120px;
					height: 8px;
					background: #4da435;
					box-sizing: border-box;
					::v-deep .el-input__inner::placeholder {
						font-size: 12px;
					}
					.search_inner {
						padding: 0 5px;
						transition: all .3s;
						box-sizing: border-box;
						width: 120px;
						line-height: 64px;
						position: absolute;
						top: 8px;
						right: 0;
						&.input-focus {
							&::before {
								content: '';
								height: 8px;
								background: transparent;
								position: absolute;
								top: -8px;
								left: 0;
								right: 0;
							}
							width: 100%;
							background: var(--background-color);
							::v-deep .el-input__inner {
								width: 100%;
							}
						}
					}
				}
			}
		}
		.bg-box {
			height: 0;
			// background: var(--background-color);
			background-color: rgba(241, 243, 248, .5);
			position: absolute;
			top: 80px;
			left: 0;
			right: 0;
			z-index: 3;
			transition: all .3s;
			&.show {
				height: 80px;
			}
			&.hide {
				height: 0;
			}
		}
	}
	::v-deep .el-input>input {
		width: 100%;
		height: 30px;
		line-height: 30px;
		border-radius: 40px;
		padding-right: 20px;
	}
</style>
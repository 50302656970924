<template>
	<div class="footer_nav">
		<div class="foot_top_box">
			<div class="banner-container">
				<div class="foot_top">
					<span class="to_home" @click="navHome">首页</span>
					<div class="qrcode_box">
						<img class="wx_img" src="@/assets/weixin.png" alt="" srcset="">
						<img class="wxqrcode" src="@/assets/qrcode.jpg" alt="" srcset="">
					</div>
				</div>
			</div>
		</div>
		<div class="banner-container">
			<div class="foot_link">
				<div class="link_box" v-for="(menu, mindex) in copyMenus" :key="mindex">
					<div class="link_title" @click.stop="menuCheck(menu)">{{menu.name}}</div>
					<div class="link_item">
						<div class="link_name" v-for="(item, index) in menu.list" :key="index">
							<span class="name_text" @click.stop="menuItem(item)">{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				copyMenus: []
			}
		},
		computed: {
			menus() {
				return this.$store.state.common.menus
			}
		},
		created() {
			this.copyMenus = Object.assign({}, this.menus.slice(1))
		},
		methods: {
			navHome() {
				this.$router.push('/')
			},
			menuCheck(menu) {
				if (menu.link) {
					this.$router.push(menu.link)
				}
			},
			menuItem(item) {
				window.scrollTo(0, 0)
				this.$store.dispatch('common/setMenu', item)
				let routerPath = item.apiKey ? item.link + '?key=' + item.apiKey : item.link
				this.$router.push(routerPath)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.footer_nav {
		min-width: 1200px;
		background: var(--background-color);
		.foot_top_box {
			border-bottom: 1px solid #e1dddd;
		}
		.foot_top {
			padding: 0 10px;
			height: 90px;
			line-height: 90px;
			display: flex;
			justify-content: space-between;
			.to_home {
				color: #666;
				font-size: 18px;
				cursor: pointer;
			}
			.qrcode_box{
				position: relative;
				&:hover {
					.wxqrcode {
						width: 200px;
						height: 200px;
						right: 30px;
					}
				}
				.wx_img {
					width: 25px;
					height: 25px;
					cursor: pointer;
				}
				.wxqrcode {
					width: 0;
					height: 0;
					max-width: none;
					transition: all .3s;
					position: absolute;
					top: 50%;
					right: 0;
				}
			}
		}
		.foot_link {
			padding-top: 20px;
			padding-bottom: 50px;
			display: flex;
			.link_box {
				padding: 10px;
				flex: 1;
				.link_title {
					display: inline-block;
					padding: 10px 0;
					font-size: 18px;
					font-weight: bold;
					cursor: pointer;
				}
				.link_item {
					.link_name {
						padding: 10px 0;
						cursor: pointer;
						.name_text {
							color: #666;
							font-size: 16px;
							&:hover {
								color: var(--theme-color);
							}
						}
					}
				}
			}
		}
	}
</style>
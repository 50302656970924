import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(() => {})
}
VueRouter.prototype.replace = function replace(location) {
	return routerReplace.call(this, location).catch(() => {})
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
		meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/search',
    name: 'search',
		meta: { title: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/search.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
		meta: { title: '公司简介' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/introduce.vue')
  },
  {
    path: '/culture',
    name: 'culture',
		meta: { title: '企业文化' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/culture.vue')
  },
  {
    path: '/honor',
    name: 'honor',
		meta: { title: '荣誉资质' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/honor.vue')
  },
  {
    path: '/building',
    name: 'building',
		meta: { title: '园区党建' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/building.vue')
  },
  {
    path: '/aboutDetail',
    name: 'aboutDetail',
		meta: { title: '园区党建' },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/detail.vue')
  },
  {
    path: '/park',
    name: 'park',
		meta: { title: '走进园区' },
    component: () => import(/* webpackChunkName: "park" */ '../views/park/park.vue')
  },
  {
    path: '/business',
    name: 'business',
		meta: { title: '入驻企业' },
    component: () => import(/* webpackChunkName: "business" */ '../views/busine/busine.vue')
  },
  {
    path: '/bdetail',
    name: 'bdetail',
		meta: { title: '入驻企业' },
    component: () => import(/* webpackChunkName: "business" */ '../views/busine/detail.vue')
  },
  {
    path: '/dynamic',
    name: 'dynamic',
		meta: { title: '园区动态' },
    component: () => import(/* webpackChunkName: "news" */ '../views/news/dynamic.vue')
  },
  {
    path: '/policy',
    name: 'policy',
		meta: { title: '政策法规' },
    component: () => import(/* webpackChunkName: "news" */ '../views/news/policy.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "news" */ '../views/news/detail.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
		meta: { title: '人才发展' },
    component: () => import(/* webpackChunkName: "talent" */ '../views/talent/recruit.vue')
  },
  {
    path: '/recruitDetail',
    name: 'recruitDetail',
    component: () => import(/* webpackChunkName: "talent" */ '../views/talent/detail.vue')
  },
  {
    path: '/notice',
    name: 'notice',
		meta: { title: '通知公告' },
    component: () => import(/* webpackChunkName: "notice" */ '../views/notice/notice.vue')
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
		meta: { title: '通知公告' },
    component: () => import(/* webpackChunkName: "notice" */ '../views/notice/detail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
		meta: { title: '联系我们' },
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact/contact.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
		meta: { title: '联系我们' },
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact/cooperation.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact/detail.vue')
  },
	{
		path: '*',
		redirect: '/',
	}
]

const router = new VueRouter({
	mode: 'hash',
	scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, form, next) => {
	let title = '林安国际商贸城'
	if (to.meta && to.meta.title) {
		document.title = to.meta.title + ' - ' + title
		next()
	} else {
		document.title = title
		next()
	}
})

export default router
